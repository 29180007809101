import { combineReducers } from 'redux'

import LoginReducer from './ReducerLogin'
import RegisterReducer from './ReducerRegister'
import AuthReducer from './ReducerAuth'
import ServerErrorReducer from './ReducerServerError'
import FbLoginReducer from './ReducerFbLogin'
import SocialLoginReducer from './ReducerSocialLogin'
import FetchJobsReducer from './ReducerFetchJobs'
import JobDetailsReducer from './ReducerJobDetails'
import functionalAreaReducer from './ReducerFunctionalArea'
import CitiesReducer from './ReducerCities'
import SendOtpReducer from './ReducerSendOtp'
import ResetPasswordReducer from './ReducerResetPassword'
import UploadedResumeReducer from './ReducerUploadResume'
import GetPersonalInfoReducer from './ReducerGetPersonalInfo'
import AddPersonalInfoReducer from './ReducerAddPersonalInfo'
import UpdateUserResumeReducer from './ReducerUpdateUserResume'
import GetUpdatedResumeReducer from './ReducerGetUpdatedResume'
import FetchRecentJobsReducer from './ReducerFetchRecentJobs'
import FiltersReducer from './ReducerFilters'
import ApplyJobFilterReducer from './ReducerApplyJobFilter'
import ApplyJobWithoutRegistrationReducer from './ReducerApplyWithoutRegistration'
import ApplyForJobReducer from './ReducerApplyJob'
import UserProfileReducer from './ReducerUserPofile'
import GetProfileImageReducer from './ReducerGetProfileImage'
import UpdateProfileImageReducer from './ReducerUpdateProfileImage'
import AddAchievementsReducer from './ReducerAddAchievements'
import AddEducationReducer from './ReducerAddEducation'
import DeleteEducationReducer from './ReducerDeleteEducation'
import UpdateEducationReducer from './ReducerUpdateEducation'
import AddExperienceReducer from './ReducerAddExperience'
import DeleteExperienceReducer from './ReducerDeleteExperience'
import UpdateExperienceReducer from './ReducerUpdateExperience'
import AddUserSkillReducer from './ReducerAddUserSkill'
import UserJobStatusReducer from './ReducerUserJobStatus'
import appliedJobsReducer from './ReducerAppliedJobs'
import alert from '../components/Alert/reducer'
import setPasswordResponse from './ReducerSetPassword'

const rootReducer = combineReducers({
    auth: AuthReducer,
    serverError: ServerErrorReducer,
    loggedUser: LoginReducer,
    registerUser: RegisterReducer,
    FbLoginReducer: FbLoginReducer,
    socialLoggedUser: SocialLoginReducer,
    fetchedJobs: FetchJobsReducer,
    jobDetails: JobDetailsReducer,
    functionalArea: functionalAreaReducer,
    cities: CitiesReducer,
    otp: SendOtpReducer,
    resetPassword: ResetPasswordReducer,
    uploadedResumeUser: UploadedResumeReducer,
    addedPersonalInfo: AddPersonalInfoReducer,
    getPersonalInfo: GetPersonalInfoReducer,
    uploadResume: UpdateUserResumeReducer,
    updatedResume: GetUpdatedResumeReducer,
    recentJobs: FetchRecentJobsReducer,
    filters: FiltersReducer,
    filteredJobs: ApplyJobFilterReducer,
    applyWithoutRegister: ApplyJobWithoutRegistrationReducer,
    jobApplied: ApplyForJobReducer,
    userProfile: UserProfileReducer,
    profileImage: GetProfileImageReducer,
    updateProfileImage: UpdateProfileImageReducer,
    addAchievements: AddAchievementsReducer,
    addEducation: AddEducationReducer,
    deleteEducation: DeleteEducationReducer,
    updateEducation: UpdateEducationReducer,
    addExperience: AddExperienceReducer,
    deleteExperience: DeleteExperienceReducer,
    updateExperience: UpdateExperienceReducer,
    addUserSkill: AddUserSkillReducer,
    userJobStatus: UserJobStatusReducer,
    appliedJobs: appliedJobsReducer,
    alert,
    setPasswordResponse
})

export default rootReducer