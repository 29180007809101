import { GET_PROFILE_IMAGE } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_PROFILE_IMAGE:
      if (action.payload.data && action.payload.data.success) {
          return {
            status: action.payload.data.success,
            user: action.payload.data.user
          }
      }
  }
  return state
}
