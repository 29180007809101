import { SOCIAL_LOGIN } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case SOCIAL_LOGIN:
      console.log(action.payload.data)
      localStorage.setItem('isLoggedin', action.payload.data.token)
      localStorage.setItem('name', action.payload.data.user.name)
      state = action.payload.data
      return state
  }
  return state
}
