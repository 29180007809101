import { SEND_FORGOT_PASSWORD_OTP } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case SEND_FORGOT_PASSWORD_OTP:
      if (action.payload.data.success) {
        return {
          status: action.payload.data.success,
          message: action.payload.data.message
        }
      } else {
        return {
          status: action.payload.data.success,
          message: action.payload.data.error
        }
      }
  }
  return state
}
