import { APPLY_WITHOUT_REGISTRATION } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case APPLY_WITHOUT_REGISTRATION:
      state = action.payload.data ? action.payload.data : {}
      return state
  }
  return state
}
