import { FETCH_RECENT_JOBS } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_RECENT_JOBS:
      if (action.payload.data && action.payload.data.success) {
        state = action.payload.data.jobs
        return state
      }
  }
  return state
}
