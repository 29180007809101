import { SERVER_ERROR } from '../actions/types'

const initialState = {
  status: false,
  error: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SERVER_ERROR:
      return {
        status: action.error.status,
        message: action.error.error
      }
  }
  return state
}
