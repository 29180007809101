import { ADD_USER_RESUME } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_USER_RESUME:
      if (action.payload.data && action.payload.data.success) {
        return {
          status: action.payload.data.success,
          message: action.payload.data.message
        }
      } else {
        return {
          status: false,
          message: 'Server error'
        }
      }
  }
  return state
}
