import { ADD_PERSONAL_INFO } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_PERSONAL_INFO:
      if (action.payload.data.success) {
        return {
          status: action.payload.data.success,
          user: action.payload.data.personalInfo
        }
      } else {
        return {
          status: action.payload.data.success,
          message: action.payload.data.error
        }
      }
  }
  return state
}
