import { FETCH_CITIES } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_CITIES:
      state = action.payload.data.cities
      return state
  }
  return state
}
