import { GET_USER_JOB_STATUS } from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case GET_USER_JOB_STATUS:
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.success
      ) {
        return {
          status: action.payload.data.success,
          isUserApplied: action.payload.data.isUserApplied,
        };
      } else {
        return {
          status: action.payload.data.success,
          error: action.payload.data.error,
        };
      }
  }

  return state;
}
