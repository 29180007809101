import { FETCH_JOBS } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_JOBS:
      state = action.payload.data.jobs
      return state
  }
  return state
}
