import { APPLY_FOR_JOB } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case APPLY_FOR_JOB:
      state = action.payload.data
      return state
  }
  return state
}
