import { ADD_EXPERIENCE } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_EXPERIENCE:
      if (action.payload.data.success) {
        return {
          status: action.payload.data.success
        }
      } else {
        return {
          status: action.payload.data.success,
          error: action.payload.data.error
        }
      }
  }

  return state
}
