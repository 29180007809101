import App, { Container } from "next/app";
import React from "react";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";

import withReduxStore from "../lib/with-redux-store";
import { setCurrentUser } from "../actions";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import { initGA, logPageView } from "../utils/Analytics";

Sentry.init({
  dsn: "https://646948c948ce427591e80d7a1d83a722@sentry.io/1513460",
});

class MyApp extends App {
  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();

    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/technocube.matomo.cloud/container_iZHklPr8.js";
    s.parentNode.insertBefore(g, s);

    /*  if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(registration => {
          console.log('service worker registration successful')
        })
        .catch(err => {
          console.warn('service worker registration failed', err.message)
        })
    } */
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    // @refactor Manage session but needs to update the method and session promiss
    if (process.browser) {
      if (localStorage.getItem("authToken")) {
        setAuthorizationToken(localStorage.getItem("authToken"));
        reduxStore.dispatch(setCurrentUser(localStorage.getItem("profile")));
      }
    }
    return (
      <Container>
        <Provider store={reduxStore}>
          <Component {...pageProps} />
        </Provider>
      </Container>
    );
  }
}

export default withReduxStore(MyApp);
