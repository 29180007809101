import { GET_USER_PROFILE } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_USER_PROFILE:
      if (action.payload.data.success) {
        state = action.payload.data ? action.payload.data.profile : {};
      }
      return state;
  }
  return state;
}
