import { ADD_USER_SKILLS } from '../actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_USER_SKILLS:
      if (action.payload.data.success) {
        return {
          status: action.payload.data.success
        }
      } else {
        return {
          status: action.payload.data.success,
          error: action.payload.data.error
        }
      }
  }
  return state
}
