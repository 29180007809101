import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import ReduxPromise from 'redux-promise'

import reducers from './reducers'

const store = createStore(reducers, composeWithDevTools(applyMiddleware(ReduxPromise, thunkMiddleware)))

export function initializeStore() {
    return store
}