import { UPDATE_PROFILE_IMAGE } from '../actions/types'

export default function (state = {}, action) {
    switch (action.type) {
        case UPDATE_PROFILE_IMAGE:
            if (action.payload.data && action.payload.data.success) {
                return {
                    status: action.payload.data.success,
                }
            }
    }
    return state
}
