import { LOGIN_USER } from '../actions/types'
import setAuthorizationToken from '../utils/setAuthorizationToken'

export default function (state = {}, action) {
  switch (action.type) {
    case LOGIN_USER:
      setAuthorizationToken(action.payload.data.token)
      localStorage.setItem('authToken', action.payload.data.token)
      localStorage.setItem('isLoggedin', action.payload.data.token)
      localStorage.setItem('name', action.payload.data.user.name)
      state = action.payload.data
      return state
  }
  return state
}
