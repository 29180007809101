import { FETCH_JOB_APPLICATION } from '../actions/types'

export default function (state = [], action) {
  switch (action.type) {
    case FETCH_JOB_APPLICATION:
      if (action.payload.data && action.payload.data.success){
        state = action.payload.data.application
      } 
      return state
  }
  return state
}
